import styled from "@emotion/styled"

export const Container = styled.div`
`

export const ContentContainer = styled.div`
`

export const ArticleInfo = styled.div`
`

export const Circle = styled.div`
`

export const Details = styled.div`
`

export const Divider = styled.span`
`
