import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'

import ArticlePage from "../components/ArticlePage/articlePage"

function ArticlePageTemplate(props) {
  const { pageContext } = props

  if (!pageContext || !pageContext.data) {
    return null
  }

  const { data, id, createdDate } = pageContext

  return data ? (
    <>
      <Helmet>
        <title>{data.title}</title>
        <meta property="og:url" content={`https://www.watchesguild.com/articles/${data.slug}`} />
        <meta property="og:description" content={data.summary}  />
        <meta name="description" content={data.summary} ></meta>
        <link rel="canonical" href={`https://www.watchesguild.com/articles/${data.slug}`}/>
        {data.picture && <meta property="og:image" content={data.picture} />}
        <script type="application/ld+json">
          {`
          {"@context":"http://schema.org","@type":"BreadcrumbList","itemListElement":[
            {"@type":"ListItem","position":1,"item":{"@id":"https://www.watchesguild.com/articles","name":"Articles"}},
            {"@type":"ListItem","position":2,"item":{"@id":"https://www.watchesguild.com/articles/${data.slug}","name":"${data.title}"}}
          ]}
          `}
        </script>
      </Helmet>
      <main
        style={{
          margin: `0 auto`,
        }} className="articlePage stdPage"
      >
        <ArticlePage {...data} id={id} published={createdDate} relatedWatches={data.relatedWatches}/>
      </main>
    </>
  ) : null
}

export default ArticlePageTemplate