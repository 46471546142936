import React from "react"
import { builder } from '@builder.io/sdk'

import {
  Container,
  Title,
  Divider,
} from "./related-news.css"
import {Articles} from "./others"

const shuffle = (array) => array.sort(() => Math.random() - 0.5);

const loadArticles = async (brand, articleId, setArticles, limit) => builder.getAll('article', {
  limit: (limit < 3) ? 3 : limit,
  model:'article',
  query: {id: {"$ne": articleId}},
  fields: "data.slug,data.title,data.picture"
}).then((articles) => {
  setArticles(shuffle(articles))
}).catch((err) => {
  console.error(err)
});

const OtherNews = ({brand, articleId, limit}) => {
  const [articles, setArticles] = React.useState([])

  React.useEffect( async () => {
    await loadArticles(brand, articleId, setArticles, limit)
  }, [articleId])

  if(!brand){
    return null
  }

  return <div class="otherArticles Articles">
    <Container>
      {articles.length > 0 && <>
      <Title>{['Other ', <b>Articles</b>]}</Title>
        <Articles articles={articles} className="articlesContainer" />
      </>}
    </Container>
  </div>
}

export default OtherNews
