import React from "react"
import { builder } from '@builder.io/sdk'

import {
  Container,
  Title,
  Divider,
  Articles,
  ArticleContainer,
  Image,
} from "./related-news.css"

import NewsletterBox from "../NewsletterBox/newsletterbox"

const Article = ({ picture, title, slug }) => (
  <ArticleContainer>
    <a href={`/articles/${slug}`}>
      <img src={picture} />
      <p>{title}</p>
    </a>
  </ArticleContainer>
)

const shuffle = (array) => array.sort(() => Math.random() - 0.5);

const loadArticles = async  (brand, articleId, setArticles) => builder.getAll('article', {
  limit: 5,
  cache:true,
  model:'article',
  query: {id: {"$ne": articleId}, data :{ brand: { id: brand.id}}},
  fields: "data.slug,data.title,data.picture"
}).then((articles) => {
  setArticles(shuffle(articles))
}).catch((err) => {
  console.error(err)
});

const RelatedNews = ({brand, articleId}) => {
  const [articles, setArticles] = React.useState([])

  React.useEffect( () => {
    async function loadData() {
      await loadArticles(brand, articleId, setArticles)
    }
    loadData()
  }, [articleId])


  if(!brand){
    return null
  }

  return <Container className="relatedTitle">
    {articles.length > 0 && <>
    <Title>Related Articles</Title>
    <Articles className="relatedArticles">
      {articles.map((article, i) => (
        <div class="Article">
          <Article {...article.data} key={i} />
        </div>
      ))}
    </Articles>
    </>}
    <div class="subscribeBoxSide"><NewsletterBox
      placeholder="Your email here"
      submit="Subscribe"
    /></div>
  </Container>
}

export default RelatedNews
