import styled from "@emotion/styled"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h4`
  width: 100%;
  /*text-align: center;*/
  margin: 32px 0;
  /*text-transform: uppercase;*/
  margin-bottom: 16px;
  color: #242629;
  font-size: 20px;
  line-height: 35px;
`

export const Divider = styled.hr`
  width: 50px;
  height: 1px;
  margin: 0 auto;
  color: black;
  margin-bottom: 32px;
`

export const Articles = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`
export const ArticleContainer = styled.div`
  margin-bottom: 20px;

  p {
    /*font-family: "IM Fell Double Pica";*/
    font-family: "Khula", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  &:hover {
    text-decoration: underline;
  }
`
export const Image = styled.div`
  margin-bottom: 8px;
  border-radius: 6px;
  background: url(${({ src }) => src});
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 180px;
`
