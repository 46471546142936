import React, { useState, useEffect }  from "react"
import { Container } from "./product.css.js"

  function GetAffilateURL(affiliateID) {

    const [affiliate, setaffiliate] = useState("");

    useEffect(() => {
      const fetchApi = (model) => `https://cdn.builder.io/api/v2/content/${model}?apiKey=2e6366bbcbb545d9878bc0053b7e0022`;
      const query = `${fetchApi("affiliate")}&query.id=${affiliateID}&fields=data.name`;

        const fetchData = async () => {
          try {
            const response = await fetch(query, {
                method: "GET",
                headers: {
                  "content-type": "application/json; charset=utf-8"
                }
                });
              const json = await response.json();
              setaffiliate(json.results[0].data.name);
          } catch (error) {
            console.log("Affiliate not found")
          }
        };

        fetchData();
    }, [affiliateID]);

    return (
      <span>Buy from {affiliate}</span>
    );

  }

const Product = ({
  price,
  reference,
  caliber,
  functions,
  powerReserve,
  caseDiameter,
  caseMaterial,
  dialColour,
  model,
  name,
  style,
  winding,
  waterResistance,
  strapBracelet,
  picture,
  buyingOptions,
}) => (
  <Container className="articleProduct watch">
    <div className="watchImage"><img src={picture} /></div>
    <div class="watchDetails">
      <h4 class="watchModel">{model}</h4>
      {reference && (
        <p><b>Reference:</b> {reference}</p>
      )}
      {caseDiameter && (
        <p>
          <b>Case/Dial:</b> {caseDiameter ? `${caseDiameter}mm` : null}{caseMaterial ? ` ${caseMaterial}` : null}{dialColour ? `, ${dialColour}` : null}<br/>
          {waterResistance ? `${waterResistance}` : null}
        </p>
      )}
      {winding && (
        <p><b>Movement: </b>{caliber ? `${caliber}, ` : null}{winding}</p>
      )}
      {functions && (
        <p><b>Functions: </b>{functions}{powerReserve ? `, ${powerReserve}h power reserve` : null}</p>
      )}
      {strapBracelet && (
        <p><b>Strap: </b>{strapBracelet}</p>
      )}
      {style && (
        <p><b>Style:</b> {style}</p>
      )}
      {price && (
        <p><b>Price: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2}).format(price)}</b></p>
      )}
      <div className="affiliateLinks">
        {buyingOptions &&
          buyingOptions.map((affiliate, i) => {
            if(affiliate.affiliate.id){
              return <a href={affiliate.affiliateLink} target="_blank">
                {GetAffilateURL(affiliate.affiliate.id)}
              </a>
            }
            return null
          })
        }
      </div>
    </div>
  </Container>
)

export default Product
