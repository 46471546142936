import styled from "@emotion/styled"

export const OtherArticles = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
`

export const OtherArticleContainer = styled.div`
  flex: 32%;
  max-width: 32%;

  p {
    font-family: "Khula", sans-serif;
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: black;
  }

  &:hover {
    text-decoration: underline;
  }
`