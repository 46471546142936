import React, { useEffect} from "react"
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns';

export const Charts =  props => {
    const {charts} = props

    useEffect( () => {

        if (charts && Array.isArray(charts)) {
            charts.forEach((chart , i) => {
                try {
                    const config = JSON.parse(chart.config)
                    new Chart(document.getElementById(`chart${i}`), {
                        type: chart.type,
                        data: {
                            labels: config.labels,
                            datasets: [{
                                type: 'scatter',
                                label: config.data.max.title,
                                data: config.data.max.values ,
                                  fill: '+1',
                                  borderWidth: 1,
                                  showLine:false,
                                  borderColor: 'rgb(255, 0, 0)',
                            },{
                                label: config.data.mean.title,
                                data: config.data.mean.values ,
                                  fill: false,
                                  borderColor: 'rgb(75, 192, 192)',
                                  borderWidth: 2
                            },{
                                type: 'scatter',
                                label: config.data.min.title,
                                data: config.data.min.values ,
                                borderWidth:  1,
                                fill: '-1',
                                showLine:false,
                                borderColor: 'rgb(115, 147, 179)',
                            }]
                        },
                        options: {
                            interaction: {
                              intersect: false,
                              mode: 'index',
                            },
                            scales: {
                                x: {
                                    type: 'time',
                                    time: {
                                        unit: 'quarter',
                                        tooltipFormat: 'MMM yyyy',
                                        displayFormats: {
                                            quarter: 'MMM yyyy'
                                        }
                                    }
                                },
                                y: {
                                    ticks: {
                                        // Include a dollar sign in the ticks
                                        callback: function(value, index, values) {
                                            return '$ ' + value;
                                        }
                                    }
                                }
                            }
                        }
                    })
                } catch (e) {
                    console.log(e)
                }
            })
        }

      }, []);

    return <div className="container">
        {charts && charts.map((chart , i) => {
            return <canvas id={`chart${i}`} width="100" height="50"></canvas>
        })}
    </div>
}