import React, { useState, useEffect }  from "react"
import { graphql, useStaticQuery } from "gatsby"
import { builder } from '@builder.io/sdk'

import {
  Container,
  ContentContainer,
  ParagraphContainer,
  Divider,
} from "./article-page.css"

import Newsletter from "../Newsletter/newsletter"
import RelatedNews from "./related-news"
import OtherNews from "./related-content"
import ArticleInfoBar from "./article-info-bar"
import Product from "./product"
import { Charts } from "./chart"
import Carousel from 'nuka-carousel';
import BreadCrumb from "../Breadcrumb/breadcrumb"
import ReadMoreSVG from "../../images/icons/readmore.inline.svg"
import Social from "./social"

const Gallery =  props => {

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => { setIsRendered(true); }, []);

  if (!isRendered) {
    return <h3>Loading...</h3>;
  }

  const { pictures } = props
  if( pictures.length == 0 ){
    return null
  }

  return pictures.length > 1 ? <Carousel>
    {pictures.map((item, i) =>  <img key={i} src={item.picture} />)}
    </Carousel> : <img src={pictures[0].picture} />
}

const Videos = props  => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => { setIsRendered(true); }, []);

  if (!isRendered) {
    return <h3>Loading...</h3>;
  }

  const { videos } = props
  if( videos.length == 0 ){
    return null
  }

  return  videos.map((item, i) =>  <iframe key={i}  id={`iplaye_${i}`} type="text/html" width="100%" height={item.height}
  src={item.url}
  frameborder="0"></iframe>)
}

const otherArticleLimit = 3;
const ArticlePage = ({
  id,
  paragraphs,
  picture,
  title,
  summary,
  data,
  writer,
  published,
  brand,
  products,
  relatedWatches,
  ...rest
}) => {


  /*function getBrandURL(brandID) {
   const [brand, setBrand] = React.useState([])

   React.useEffect( () => {
    async function loadData() {
    await loadBrand(brandID, setBrand)
   }
    loadData()
   }, [brandID])

    /*const BrandURL = await fetch(`${fetchApi('brand')}&query.id=${brandID}&fields=data.name`)
      .then(response => response.json())
      .then(resultData => {
       console.log(resultData)
     })
    //const brand = await BrandURL.json();
    console.log(`${fetchApi('brand')}&query.id=${brandID}&fields=data.name`)
    console.log(BrandURL)
        return "RolexHAHAHA"
  }

  const loadBrand = async (brandId, setBrand) => builder.getAll('brand', {
    cache:true,
    model:'brand',
    query: {id: "${brandID}"},
    fields: "data.name"
    }).then((brand) => {
      setBrand(shuffle(brand))
    }).catch((err) => {
      console.error(err)
  });
  */

  function GetBrandURL(brandID,cbURL) {

    const [brand, setbrand] = useState("");

    useEffect(() => {
      const fetchApi = (model) => `https://cdn.builder.io/api/v2/content/${model}?apiKey=2e6366bbcbb545d9878bc0053b7e0022`;
      const query = `${fetchApi("brand")}&query.id=${brandID}&fields=data.name`;

        const fetchData = async () => {
          try {
            const response = await fetch(query, {
                method: "GET",
                headers: {
                  "content-type": "application/json; charset=utf-8"
                }
                });
              const json = await response.json();
              setbrand(json.results[0].data.name.toLowerCase().replace(/\s/g, ''));
          } catch (error) {
            setbrand(cbURL.toLowerCase().replace(/\s/g, ''));
          }
        };

        fetchData();
    }, [brandID, cbURL]);

    return (
      <a href={`/brands/${brand.toLowerCase().replace(/\s/g, '')}`} class="readMore">Read more
      <span>
        <ReadMoreSVG/>
      </span>
     </a>
    );

  }

  const getInitials = name =>
    name
      .split(" ")
      .map(x => x[0])
      .join("")

  return <Container>
    <div className={picture ? `hasImage heroSection` : `heroSection`}>
      <div className="heroContainer">
        <BreadCrumb section="Articles" sectionURL="/articles" pageName={title} />
        <div class="imageContainer container">
          <h2>{title}</h2>
          {picture ? <img src={picture} className="hero" /> : null}
        </div>
      </div>
    </div>
    <div class="container">
      <div class="articleInfo">
        <div class="articleAuthor">
          <div class="articleAuthorIcon">{writer?.value?.data?.image || getInitials(writer?.value?.data?.title || "The Editorial Team")}</div>
          <div class="articlePublish">
            <b>{writer?.value?.data?.title || "The Editorial Team"}</b><br />
            <span>Published: {new Intl.DateTimeFormat('en-HK', { month: 'short', day: '2-digit', year: 'numeric' }).format(published)}</span>
          </div>
        </div>
        <Social iconColor="black" class="articleSocial"/>
      </div>
      <ContentContainer className="mainContent">
        <div class="pageContent">
          {paragraphs &&
            paragraphs.map((paragraph, j) => {
              const {
                title,
                description,
                contents,
                picture,
                products,
              } = paragraph
              return (
                <>
                  <div className="articleSection">
                    <div key={j} className="articleParagraph">
                      {title && <h3>{title}</h3>}
                      {description && <h4>{description}</h4>}
                      {contents && contents.map((item, j) => {
                        return (
                          <React.Fragment key={j}>
                          {item.content && <div
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          />}
                          {item.gallery && <Gallery pictures={item.gallery} /> }
                          {item.videos && <Videos videos={item.videos} /> }
                          {item.charts && <Charts charts={item.charts} /> }
                          </React.Fragment>
                        )
                      })}
                    </div>
                    {picture &&
                      <div className="atricleImages">
                        <div className="contentImage">
                          <img src={picture} />
                        </div>
                      </div>
                    }
                    {products && products.length > 0 &&
                      <div className="articleProducts">
                        <div class="watchTitle">Our Pick</div>
                        <div className="watches">
                            {products.map((product, i) => {
                              if(product.product.value){
                                const { data } = product.product.value
                                return <Product {...data} key={i} />
                              }
                              return null
                            })}
                        </div>
                      </div>
                    }
                  </div>
                </>
              )
            })
          }
          {relatedWatches ?
          <div class="relatedWatches">
            <div class="watchTitle">Related Models</div>
            <div class="watches">
            {relatedWatches.map((watch, i) => {
              if(watch.watch.value){
                const relatedwatch = watch.watch.value.data
                return <div class="watch">
                <div class="watchImage">
                  <img src={relatedwatch.picture} />
                </div>
                <div class="watchDetails">
                  <h4 class="watchModel">{relatedwatch.model}</h4>
                  {relatedwatch.reference && (
                    <p><b>Reference:</b> {relatedwatch.reference}</p>
                  )}
                  {relatedwatch.caseDiameter && (
                    <p>
                      <b>Case/Dial:</b> {relatedwatch.caseDiameter ? `${relatedwatch.caseDiameter}mm` : null}{relatedwatch.caseMaterial ? ` ${relatedwatch.caseMaterial}` : null}{relatedwatch.dialColour ? `, ${relatedwatch.dialColour}` : null}<br/>
                      {relatedwatch.waterResistance ? `${relatedwatch.waterResistance}` : null}
                    </p>
                  )}
                  {relatedwatch.winding && (
                    <p><b>Movement: </b>{relatedwatch.caliber ? `${relatedwatch.caliber}, ` : null}{relatedwatch.winding}</p>
                  )}
                  {relatedwatch.functions && (
                    <p><b>Functions: </b>{relatedwatch.functions}{relatedwatch.powerReserve ? `, ${relatedwatch.powerReserve}h power reserve` : null}</p>
                  )}
                  {relatedwatch.strapBracelet && (
                    <p><b>Strap: </b>{relatedwatch.strapBracelet}</p>
                  )}
                  {relatedwatch.style && (
                    <p><b>Style:</b> {relatedwatch.style}</p>
                  )}
                  {relatedwatch.price && (
                    <p><b>Price: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2}).format(relatedwatch.price)}</b></p>
                  )}
                  {relatedwatch.brand.id ?
                    GetBrandURL(relatedwatch.brand.id)
                    :
                    <a href={`/brands/${brand?.value?.data?.name.toLowerCase().replace(/\s/g, '')}`} class="readMore">Read more
                      <span>
                        <ReadMoreSVG/>
                      </span>
                    </a>
                  }
                </div>
              </div>
              }
              return null
            })
            }
            </div>
          </div>
          :
          null
        }
        </div>
        <RelatedNews brand={brand} articleId={id} />
      </ContentContainer>
      <OtherNews brand={brand} articleId={id} limit={otherArticleLimit}/>
    </div>
    <Newsletter placeholder="Your email here" submit="Subscribe" />
  </Container>

}
export default ArticlePage
