import React from "react"

import { Image } from "./related-news.css"

import { OtherArticles, OtherArticleContainer } from "./related-content.css"

const Article = ({ picture, title, slug }) => (
  <OtherArticleContainer className="Article">
    <a href={`/articles/${slug}`}>
      <img src={picture} />
      <p className="articleText">{title}</p>
    </a>
  </OtherArticleContainer>
)

export const Articles = ({articles}) => (
  <OtherArticles>
    {articles.map((article, i) => (
      <Article {...article.data} key={i} />
    ))}
  </OtherArticles>
)
