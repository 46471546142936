import styled from "@emotion/styled"

export const Container = styled.div`
  padding: 32px 0 20px 0px;/*16px 32px;*/
  text-align: left;
  position: relative;
  display: flex;
  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(0,0,0,0.1);
  align-items: center;
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  height: auto;/*100px;*/
  width: 100%;

  ${({ img }) => img && `width: 300px;`}
`

export const Heading = styled.div`
  color: black;
  margin-bottom: 8px;
  font-size: 28px;/*1.2rem;*/
  line-height: 34px;

  ${({ img }) => img && `color: white;`}
`

export const InputContainer = styled.div`
  position: relative;
`

export const Input = styled.input`
  border-radius: 6px;
  /*border: solid 2px #b6b6b6;*/
  border: 0px;
  padding: 8px 16px;
  font-family: "Raleway";
  width: 100%;
  font-size: 0.6rem;
  height: 47px;
  box-shadow: 5.56777px 7.95396px 39.7698px rgba(0, 0, 0, 0.1);
`

export const Button = styled.button`
  color: white;
  border-radius: 6px;/*500px;*/
  /*border: 1px solid #777;*/
  border: 0px;
  font-size: 12px; /*0.8rem;*/
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 16px 12px 16px;/*0 16px;*/
  position: absolute;
  right: 4px    ;
  bottom: 0;
  top: 4px;
  cursor: pointer;
  box-shadow: 0px 8.74936px 39.7698px rgba(0, 0, 0, 0.2);
  background: rgb(157, 157, 157);
  background: rgb(78, 78, 78);
  background: -moz-linear-gradient(
    345deg,
    rgba(78, 78, 78, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    345deg,
    rgba(78, 78, 78, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    345deg,
    rgba(78, 78, 78, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4e4e4e",endColorstr="#000000",GradientType=1);

  &:hover {
    background: black;
  }
`
