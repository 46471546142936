import styled from "@emotion/styled"

import FacebookSVG from "./facebook.inline.svg"
import TwitterSVG from "./twitter.inline.svg"
import PinterestSVG from "./pinterest.inline.svg"

const sharedCss = `
  width: 20px;
  height: 20px;
`

export const SocialContainer = styled.div`
  display: flex;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.iconColor && props.iconColor};
  }
`

export const Container = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #f3e6e5;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  cursor: pointer;

  &:hover {
    background: #efefef;
  }
`

export const Facebook = styled(FacebookSVG)`
  ${sharedCss}
`

export const Twitter = styled(TwitterSVG)`
  ${sharedCss}
`

export const Pinterest = styled(PinterestSVG)`
  ${sharedCss}
`
