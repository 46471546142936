import React from "react"

import {
  SocialContainer,
  Container,
  Facebook,
  Twitter,
  Pinterest,
} from "./social.css"

const Social = ({
  iconColor = "white"
}) => (
  <SocialContainer iconColor={iconColor} class="socialIcons">
    <Container class="socialIcon">
      <a href="https://www.facebook.com/WatchesGuild" target="_blank"><Facebook /></a>
    </Container>
    <Container class="socialIcon">
      <a href="https://twitter.com/WatchesGuild" target="_blank"><Twitter /></a>
    </Container>
    <Container class="socialIcon">
      <a href="https://www.pinterest.com/watchesguild" target="_blank"><Pinterest /></a>
    </Container>
  </SocialContainer>
)

export default Social
