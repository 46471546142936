import styled from "@emotion/styled"

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .hero {
    max-height: 480px;
    object-fit: cover;
  }

  .inline-image {
    width: 100%;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 34px;
  }

  .inline-image img {
    max-width: 100%;
    display: block;
    width: 100%;
  }
`

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr 2fr;/*5fr 3fr;*/
  grid-column-gap: 60px;/*32px;*/
  padding: 0px;

  @media only screen and (max-width: 768px) {
    display: block;
    grid-template-columns: 1fr;
    /*padding: 16px;%/

    h1 {
      padding: 16px;
    }
  }
`

export const ParagraphContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;

  @media only screen and (max-width: 480px) {
    /*padding: 16px;*/
  }
`

export const Divider = styled.hr`
  width: 50px;
  height: 1px;
  margin: 0 auto;
`
